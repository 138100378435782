<template>
  <div
    class="from-config"
    v-loading.fullscreen.lock="loading"
  >
    <el-tabs
      v-model="activeName"
      @tab-click="changeTab"
    >
      <!-- <el-tab-pane name="first"> -->
      <ng-form-design
        ref="formDesign"
        :config="formConfig"
      >
        <template slot="controlButton">
          <el-button
            type="text"
            size="medium"
            @click="initDemo(1)"
          >示例1</el-button>
          <el-button
            type="text"
            size="medium"
            @click="initDemo(2)"
          >示例2</el-button>
          <el-button
            type="text"
            size="medium"
            @click="initDemo(3)"
          >示例3</el-button>
        </template>
        <!-- 自定义属性配置 -->
        <template
          slot="custom-properties"
          slot-scope="{selectItem}"
        >
          <Properties :selectItem="selectItem" />
        </template>
        <!-- <template slot="formName">
          <span> ng-form-element 示例 </span>
        </template> -->
        <!-- <template
            slot="extend-tab"
            slot-scope="{data }"
          >
            <el-tab-pane
              label="扩展属性"
              name="select"
            >
              扩展测试插槽-加tab:: (组件数量):: {{data.list.length}}
            </el-tab-pane>
          </template> -->
        <!-- <template
            slot="form-extend-properties"
            slot-scope="{ data}"
          >
            扩展测试插槽(组件数量):: {{data.list.length}}
          </template> -->

      </ng-form-design>
      <!-- </el-tab-pane> -->
      <el-tab-pane
        label="表单查看"
        name="second"
        v-if="development"
      >
        <el-alert
          title="测试表单预览"
          type="warning"
        >
        </el-alert>
        <ng-form-build
          ref="formbuild"
          :formTemplate="formTemplate"
          :models="models"
          :custom-components="customComponents"
          :config="formConfig"
        />

        <el-button
          type="primary"
          size="mini"
          @click="validator()"
        >验证</el-button>
        <el-button
          type="primary"
          size="mini"
          @click="getData()"
        >获取数据</el-button>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import uploadImg from './components/uploadImg'
import Properties from './components/properties'
import { httpUploadFileUrl } from './packages/form/src/utils'
import api from '@/api'
export default {
  name: 'App',
  /* eslint-disable */
  components: { uploadImg, Properties },
  /* eslint-enable */
  data () {
    return {
      activeName: 'first',
      models: {},
      formTemplate: {},
      formConfig: {
        httpConfig: (config) => {
          config.headers.aaaa = 'bbbb'
          return config
        }
      },
      customComponents: [
        {
          type: 'uploadImg',
          label: '上传图片',
          component: uploadImg,
          options: {
            defaultValue: [],
            multiple: false,
            hidden: false, // 是否隐藏，false显示，true隐藏
            disabled: false,
            width: '100%',
            tooptip: '', // 提示
            limit: 3,
            placeholder: '上传',
            headers: {},
            action: httpUploadFileUrl,
            listType: 'picture-card'
          },
          model: '',
          key: '',
          rules: [
            {
              required: false,
              message: '必填项'
            }
          ]
        }
      ],
      loading: false
    }
  },
  mounted () {
    // console.log(this.$route.query.id, this.$route.query.active)
    if (this.$route.query.active === '1' && this.$route.query.id) {
      this.loading = true
      this.getFormData()
    }
  },
  computed: {
    development () {
      return process.env.NODE_ENV === 'development'
    }
  },
  watch: {
    $route (n) {
      if (n.query.active === '1' && n.query.id) {
        this.loading = true
        this.getFormData()
      }
    }
  },
  methods: {
    getFormData () {
      this.formTemplate = {}
      /* eslint-disable */
      this.axios.get(api.getWorkConfigHtml, { params: { configId: this.$route.query.id } }).then(res => {
        if (res.data.data) {
          const json = eval('(' + res.data.data + ')')
          if (json) {
            this.formTemplate = json
            this.$refs.formDesign.initModel(this.formTemplate)
          }
        }
        setTimeout(() => {
          this.loading = false
        }, 1000)
      })
      /* eslint-enable */
    },
    initDemo (index) {
      let json = null
      if (index === 1) {
        json = require('./data/basic.json')
      } else if (index === 2) {
        json = require('./data/tablebatch.json')
      } if (index === 3) {
        json = require('./data/validator1.json')
      }
      if (json) {
        this.$refs.formDesign.initModel(json)

        this.formTemplate = json
      }
    },
    validator () {
      this.$refs.formbuild.validator().then((valid) => {
        if (valid) {
          this.$message.info('验证通过')
        } else {
          this.$message.error('验证不通过')
        }
      })
    },
    changeTab (v) {
      if (v && v.name === 'second') {
        this.formTemplate = this.$refs.formDesign.getModel()
        const list = this.formTemplate.list
        if (list) {
          const templateModels = list.map(t => t.model)
          for (const i in this.models) {
            if (!templateModels.includes(i)) {
              delete this.models[i]
            }
          }
          // 2021-04-06 顺带重置models 将
        }
        // this.models = {}
      }
    },
    getData () {
      this.$refs.formbuild.getData().then((data) => {
        console.log('data', data)
      })
    }
  }
}
</script>

<style lang="stylus" scoped></style>
