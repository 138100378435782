<template>
  <div>
    <el-upload
      v-if="!preview"
      action="https://img3.winshang.com/api/upload"
      list-type="picture-card"
      v-model="models"
      :file-list="models[record.defaultValue]"
      :on-success="handlePictureCardPreview"
      :on-remove="handleRemove"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
  </div>
</template>
<script>
export default {
  props: {
    record: { // 组件数据
      type: Object,
      required: true
    },
    models: { // 表单数组
      type: Object,
      required: true
    },
    disabled: { // 是否禁用
      type: Boolean,
      default: false
    },
    preview: { // 是否当前是预览
      type: Boolean,
      default: false
    }
  },
  methods: {
    handlePictureCardPreview (file) {
      console.log(file)
      console.log(this.models)
      // this.models.push(file.Data.FileID)
    },
    handleRemove (file) {
      console.log(file)
    }
  }
}
</script>
